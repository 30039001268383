import React,{useState, useEffect} from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import OrderModal from "../../components/orderModel";
import { dataportfolio, meta,dataPriceList } from "../../content_option";
import {getAllProduct} from "../../utils/getAllProduct";
export const MenPerfume = () => {
    const [modalShow, setModalShow] = useState(false)
    const [itemClick, setItemClick] = useState([])
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const handleClickProduct = (data) =>{
      setModalShow(true)
      setItemClick(data)
    }
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const products = await getAllProduct();
          setProducts(products.data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, []);
  return (
    <HelmetProvider>
      <Container className="About-header">
      <OrderModal 
          show={modalShow}
          onHide={()=> setModalShow(false)}
          data={itemClick}
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title> Perfume | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Men Perfume </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row>
          {
            products && products.map((item, index)=>(
              item.published && 
              <Col md={4} className="mb-3" key={item.id}>
                <div className="bg-yellow-500 p-3" onClick={()=>handleClickProduct(item)}>
                    <div className="border-2 border-solid border-white w-fit py-1 px-2 float-end -rotate-6">{item.tag ? item.tag : "No Tag"}</div>
                    <img src={`/uploads/${item.image}`} className="w-full transition-transform transition-slow hover:transform-scale-125"/>
                    <div className="flex flex-row justify-between items-end py-7">
                        <div>
                          <h1 className="capitalize text-2xl mb-2">{item.name}</h1>
                          <p className="capitalize">{item.perfumeOil}</p> 
                        </div>
                        <p>From ${item.price}</p>
                    </div>
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>
    </HelmetProvider>
  );
};
