import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { MdOutlineCancel } from "react-icons/md";
import { createOrder } from '../../utils/orders';
import { getColor } from '../../utils/color';
import { getSize } from '../../utils/size';
import Spinner from 'react-bootstrap/Spinner';  // Import spinner component
import toast, { Toaster } from 'react-hot-toast';

const OrderModal = (props) => {
    const { data } = props;
    const [orderDetails, setOrderDetails] = useState({
        name: '',
        phone: '',
        location: '',
        size: '',
        color: ''
    });
    const [color, setColor] = useState([]);
    const [size, setSize] = useState([]);
    const [colorLoading, setColorLoading] = useState(true);
    const [colorError, setColorError] = useState(null);
    const [sizeLoading, setSizeLoading] = useState(true);
    const [sizeError, setSizeError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);  // New state for processing

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrderDetails({
            ...orderDetails,
            [name]: value
        });
    };
    const handleClose = () =>{
        props.onHide();
        setOrderDetails({
            name: '',
            phone: '',
            location: '',
            size: '',
            color: ''
        });
    }
    const handleCreateOrder = async (productId) => {
        if (!orderDetails.name || !orderDetails.phone || !orderDetails.location || !orderDetails.size || !orderDetails.color) {
            toast.error("Please fill out all fields.");
            return;
        }
    
        const orderData = {
            productId: productId,
            name: orderDetails.name,
            phone: orderDetails.phone,
            location: orderDetails.location,
            sizeId: Number(orderDetails.size),
            colorId: Number(orderDetails.color)
        };
    
        setIsProcessing(true);  // Start processing
    
        try {
            const response = await createOrder(orderData);
    
            // Assuming createOrder shows a success toast on 201 status.
            if (response && response.status === 201) {
                setOrderDetails({
                    name: '',
                    phone: '',
                    location: '',
                    size: '',
                    color: ''
                });
                props.onHide();
            }
        } catch (error) {
            console.error("Order creation failed:", error);
            // The error toast is handled inside createOrder function.
        } finally {
            setIsProcessing(false);  // Stop processing
        }
    };
    

    useEffect(() => {
        const fetchColor = async () => {
            try {
                const color = await getColor();
                setColor(color.data);
            } catch (error) {
                setColorError(error.message);
                toast.error("Failed to fetch colors.");
            } finally {
                setColorLoading(false);
            }
        };

        const fetchSize = async () => {
            try {
                const size = await getSize();
                setSize(size.data);
            } catch (error) {
                setSizeError(error.message);
                toast.error("Failed to fetch sizes.");
            } finally {
                setSizeLoading(false);
            }
        };

        fetchColor();
        fetchSize();
    }, []);

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='m-0 p-0'>
                    <Row className='m-0'>
                        <Col md={5} sm={12} className='p-0'>
                            <div className="bg-yellow-500 p-3 h-full">
                                <div className="border-2 border-solid border-white w-fit py-1 px-2 float-end -rotate-6">New</div>
                                <img src={`/uploads/${data.image}`} className="w-full" />
                                <div className="flex flex-row justify-between items-end py-7 border-b border-solid border-white">
                                    <div>
                                        <h1 className="capitalize text-2xl mb-2">{data.name}</h1>
                                        <p className="capitalize">{data.perfumeOil}</p>
                                    </div>
                                    <p>From ${data.price}</p>
                                </div>
                                <p className='pt-3'>{data.desc}</p>
                            </div>
                        </Col>
                        <Col md={7} sm={12} className='p-0'>
                            <div className="bg-slate-100 p-3 h-full w-full">
                                <div className='text-black float-end' onClick={()=>handleClose()}><MdOutlineCancel /></div>
                                <div className='text-black pt-5 pb-3'>
                                    <p className='uppercase font-bold mb-2'>Torin Perfume</p>
                                    <p className='capitalize mb-2'>Fill in your information, we will contact you via phone call or telegram to confirm your order</p>
                                </div>
                                <Form className='py-7'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlPerfume">
                                        <Form.Control type="text" placeholder="Perfume" value={data.name} readOnly />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlName">
                                        <Form.Control type="text" placeholder="Name" name="name" value={orderDetails.name} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlPhone">
                                        <Form.Control type="text" placeholder="Phone" name="phone" value={orderDetails.phone} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlLocation">
                                        <Form.Control type="text" placeholder="Location" name="location" value={orderDetails.location} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Select aria-label="Size" className='mb-3' name="size" value={orderDetails.size} onChange={handleInputChange}>
                                        <option value="">Choose Size</option>
                                        {sizeLoading ? (
                                            <option>Loading sizes...</option>
                                        ) : (
                                            size.map(item => (
                                                <option value={`${item.id}`} key={item.id}>{item.name}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                    <Form.Select aria-label="Color" className='mb-3' name="color" value={orderDetails.color} onChange={handleInputChange}>
                                        <option value="">Choose Color</option>
                                        {colorLoading ? (
                                            <option>Loading colors...</option>
                                        ) : (
                                            color.map(item => (
                                                <option value={`${item.id}`} key={item.id}>{item.name}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                    <Button 
                                        type="button" 
                                        className='bg-slate-500 rounded-none py-2 px-6 font-bold float-end mb-7 border-0 hover:bg-slate-300 hover:text-black' 
                                        onClick={() => handleCreateOrder(data.id)}
                                        disabled={isProcessing}  // Disable button when processing
                                    >
                                        {isProcessing ? (
                                            <Spinner animation="border" size="sm" />  // Show spinner when processing
                                        ) : (
                                            'BUY'
                                        )}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OrderModal;
