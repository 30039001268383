export const getSize = async () => {
    try {
        const response = await fetch('https://torin-server.vercel.app/api/size', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': '8282a7d8f5f20b95135f70706c744be38c1f5bdd'
            },
            
          });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch size:', error);
      return [];
    }
  };