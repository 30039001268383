import axios from 'axios';
import toast from 'react-hot-toast';

export const createOrder = async (data) => {
    const url = "https://torin-server.vercel.app/api/order";
    
    try {
        const response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
                'x-api-key': '8282a7d8f5f20b95135f70706c744be38c1f5bdd'
            }
        });

        if (response.status === 201) {
            toast.success("Order placed successfully!",{ duration: 5000});
            return response;  // Successful response
        } else {
            toast.error(`Failed to create order. Status code: ${response.status}`,{ duration: 5000});
            // console.log("Response:", response.data);
            return null;  // Indicate a failure
        }
    } catch (error) {
        console.error("Error creating order:", error);
        if (error.response) {
            toast.error(`Error: ${error.response.data.message || "Failed to create order."}`,{ duration: 5000});
            // console.log("Error response:", error.response.data);
        } else {
            toast.error("Failed to create order. Please try again.",{ duration: 5000});
        }
        return null;  // Indicate a failure
    }
};
